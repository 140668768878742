import React from 'react'
import main from '../DevelopmentCenter/Assets/detail image 4.png'
const Footer = () => {
  return (
    <div className=''>
        <img src={main} alt="" />
    </div>
  )
}

export default Footer