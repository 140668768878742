import React from 'react'
import main from '../Asmid/Assets/detail.png'
const Footer = () => {
  return (
    <div>
        <img src={main} alt="" />
    </div>
  )
}

export default Footer