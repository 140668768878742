import React from "react";
import Recent from "../components/RecentWork/Recent";
import Contact from "../components/Home/Contact";
import CaseStudy from "../components/RecentWork/CaseStudy";

const RecentWork = () => {
  return (
    <div>
      <Recent />
      <CaseStudy />
      <Contact />
    </div>
  );
};

export default RecentWork;
