import React from 'react'
import main from '../Assets/Frame 31.png'
const Footer = () => {
  return (
    <div className=''>
        <img src={main} alt="" />
    </div>
  )
}

export default Footer