import React from 'react'
import main from '../../CasStydies/LocalCaregiver/Assets/Group 77744.png'
const Footer = () => {
  return (
    <div>
        <img src={main} alt="" />
    </div>
  )
}

export default Footer