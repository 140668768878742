import React from 'react'
import main from '../../CasStydies/Skiwit/Assets/detail.png'
const Footer = () => {
  return (
    <div className=''>
        <img src={main} alt="" />
    </div>
  )
}

export default Footer