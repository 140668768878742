import React from "react";
const Solution = () => {
  return (
    <div className=" md:mx-44 mx-4">
      <h1 className=" text-[#000] md:text-[32px] text-[22px] font-bold">
        Solution <span className=" text-black font-normal">We Provided</span>
      </h1>
      <p className=" text-[#606060] font-normal md:text-[17px] text-[14px] pt-5">
        Our solutions redefine app development, offering tailored resolutions to
        security, performance, and user experience challenges, fostering a
        cutting-edge and user-centric digital evolution.
      </p>

      <div className=" md:flex block justify-between">
        <div className=" flex flex-col gap-5 pt-4">
          <div className=" flex gap-4">
            <h1 className=" text-[#000] text-[20px] font-medium">1</h1>
            <div>
              <h2 className=" text-[#000] text-[20px] font-medium">
                Real-time Synchronization and Performance Issues with Firebase
              </h2>
              <p className=" text-[#606060] text-[17px] font-normal">
                Optimize data structure and queries to reduce the load on
                Firebase. Implement pagination for fetching workout data to
                prevent excessive data transfer. Use indexing appropriately to
                speed up queries. Additionally, consider implementing a caching
                mechanism on the client side to reduce the reliance on real-time
                updates for non-critical data.
              </p>
            </div>
          </div>

          <div className=" flex gap-4">
            <h1 className=" text-[#000] text-[20px] font-medium">2</h1>
            <div>
              <h2 className=" text-[#000] text-[20px] font-medium">
                Complexity in Implementing Custom Cloud Functions
              </h2>
              <p className=" text-[#606060] text-[17px] font-normal">
                Break down complex tasks into smaller, manageable functions.
                Leverage modularization to make the codebase more maintainable.
                Implement proper error handling and logging in Cloud Functions
                to identify and troubleshoot issues effectively. Consider
                thorough testing, both unit and integration, to ensure the
                reliability of the Cloud Functions.
              </p>
            </div>
          </div>

          <div className=" flex gap-4 pt-4">
        <h1 className=" text-[#000] text-[20px] font-medium">3</h1>
        <div>
          <h2 className=" text-[#000] text-[20px] font-medium">
          User Engagement and Retention
          </h2>
          <p className=" text-[#606060] text-[17px] font-normal">
          Implement gamification elements, such as achievements, badges, or challenges, to make the app more engaging. Use Firebase Analytics to understand user behavior and preferences, allowing for the customization of motivational messages and workout suggestions. Regularly update the app with new features and content to keep it fresh and appealing to users.
          </p>
        </div>
      </div>
        </div>
      </div>

      <div className=" flex gap-4 pt-4">
        <h1 className=" text-[#000] text-[20px] font-medium">4</h1>
        <div>
          <h2 className=" text-[#000] text-[20px] font-medium">
            Cross-platform Consistency with Flutter
          </h2>
          <p className=" text-[#606060] text-[17px] font-normal">
            Thoroughly test the app on both platforms to identify and address
            any design or functionality inconsistencies. Leverage Flutter's
            platform-specific widgets and features where necessary. Stay updated
            with Flutter updates and adapt the codebase accordingly to benefit
            from platform-specific improvements.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Solution;
